import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import IpadFrame from '../components/shared/Frames/IpadFrame';
import IphoneFrame2 from '../components/shared/Frames/IphoneFrame2';
import { FaTabletAlt, FaMobileAlt } from 'react-icons/fa';
import { Tile } from 'styled-icons/simple-icons';
import SEO from '../components/seo';

const Showroom = () => {
	const [widgeturl] = useQueryParam('widgeturl', StringParam);
	const [device, setDevice] = useState('mobile');
	const [isBrowser, setIsBrowser] = useState(false);
	const [mobile, setMobile] = useState({ w: 0, h: 0 });
	const [tablet, setTablet] = useState({ w: 0, h: 0 });

	const [title, setTitle] = useState('Apexedge site');

	useEffect(() => {
		const url = widgeturl;

		// Find example: 'cobranded-skin' and 'negotiation' in the URL
		const regex = /\/([^/]+)\/v1\/([^/]+)\//;
		const match = url.match(regex);

		if (match && match.length >= 3) {
			const temptitle = match[1];
			const auxtitle = match[2];
			setTitle(`${temptitle} - ${auxtitle}`);
		}

		// Add new title
		document.title = title;
	}, [title]);

	useEffect(() => {
		const screenHeight = window.innerHeight - 50;

		setMobile({
			h: screenHeight,
			w: screenHeight * 0.46,
		});
		setTablet({
			h: screenHeight,
			w: screenHeight * 0.75,
		});

		setIsBrowser(true);
	}, []);

	return (
		<div className="showroom">
			<SEO
				title={title}
				description={'showroom page'}
				keywords={''}
				pathname={'/showroom'}
			/>
			<h1 className="page-title" style={{ display: 'none' }}>
				{title}
			</h1>
			{isBrowser && (
				<div className="showroom-frames">
					{(device === 'mobile' || device === 'both') && (
						<IphoneFrame2>
							<iframe
								title="Mobile"
								src={widgeturl}
								width={`${mobile.w < 426 ? mobile.w : 426}px`}
								style={{ height: `${mobile.h < 844 ? mobile.h : 844}px` }}
								// scrolling={data.iframe.scrolling}
								// frameBorder={data.iframe.frameBorder}
								loading="lazy"
								// onLoad={(e) => {
								// 	console.log('Iframe Mobile LOAD ', e.target);

								// 	// e.target.contentDocument.querySelector('html').ccs;
								// }}
								// allowTransparency={data.iframe.allowTransparency}
							/>
						</IphoneFrame2>
					)}
					{(device === 'tablet' || device === 'both') && (
						<IpadFrame>
							<iframe
								title="Tablet"
								src={widgeturl}
								width={`${tablet.w < 820 ? tablet.w : 820}px`}
								style={{ height: `${tablet.h < 1180 ? tablet.h : 1180}px` }}
								// scrolling={data.iframe.scrolling}
								// frameBorder={data.iframe.frameBorder}
								loading="lazy"
								// onLoad={() => {
								// 	console.log('Iframe ', this);
								// }}
								// allowTransparency={data.iframe.allowTransparency}
							/>
						</IpadFrame>
					)}
				</div>
			)}
			<div className="showroom-buttons">
				<button
					type="button"
					className={`button-top ${device === 'mobile' ? 'btn-active' : ''}`}
					onClick={() => setDevice(device === 'mobile' ? 'tablet' : 'mobile')}
					title={`Change to ${device === 'mobile' ? 'tablet' : 'mobile'}`}
				>
					{device === 'mobile' ? <FaTabletAlt /> : <FaMobileAlt />}
				</button>
			</div>
		</div>
	);
};

Showroom.propTypes = {};

export default Showroom;
