import React from 'react';

const IphoneFrame2 = ({ children }) => {
	return (
		<div className="phone-frame2">
			<div className="phone-sides">
				<div className="phone-header">
					<div className="speaker" />
					<div className="camera" />
				</div>
				{children}
			</div>
		</div>
	);
};

export default IphoneFrame2;
